<div *ngIf="dialogRef?.close" class="modal-close-icon" (click)="close();">
  <mat-icon size="10px">close</mat-icon>
</div>
<div class="flex flex-col overflow-hidden h-[100%]">
  <div class="flex flex-1 overflow-y-auto flex-col-reverse">
    <vex-scrollbar>
      <!-- Messages -->
      <div class="messages mt-3 pb-6"
           [ngClass]="{
             'messages__user-message': message.role === 'user'
           }"
           *ngFor="let message of messages; let i = index">
        <div>
          <div [ngClass]="{'text-right': message.role === 'user'}"
               class="messages__chat-info text-blue-500 mb-2 align"
          >
            <div class="messages__chat-info__avatar">
              <app-avatar-photo
                [imgUrl]="message.role === 'user' ? user?.avatar : aiLogo"
                [name]="message.role === 'user' ? user?.name : aiName"
                displayType="circle"
              >
              </app-avatar-photo>
            </div>
            <div class="messages__chat-info__name">
              {{message.role === 'user' ? userName : aiName}}
            </div>
            <div class="messages__chat-info__time">
              {{message.createdAt | date:dateFormatService.getFormat() + ' h:mm a' : null : dateFormatService.getLocale()}}
            </div>
          </div>
          <div class="messages__chat-message"
               [ngClass]="{'messages__chat-message_prints': message?.prints && message.role !== 'user'}"
          >
             <showdown [value]="message.content"></showdown>
          </div>

          <div *ngIf="messages.length === (i + 1) &&  message.role === 'assistant' || message?.rating" class="rating mt-2 ml-2">
            <button class="text-gray-400"
                    [ngClass]="{'text-green-400': message?.rating?.isGood}"
                    *ngIf="!message?.rating || message?.rating?.isGood"
                    [@like]="likeState"
                    [disabled]="message?.rating"
                    (click)="openRatingComment(message.id, true)"
            >
              <mat-icon class="hover:text-green-400 text-sm">thumb_up</mat-icon>
            </button>
            <button class="text-gray-400"
                    [ngClass]="{'text-red-400': !message?.rating?.isGood && message?.rating}"
                    *ngIf="!message?.rating || !message?.rating?.isGood"
                    [@like]="likeState"
                    [disabled]="message?.rating"
                    (click)="openRatingComment(message.id,false)"
            >
              <mat-icon class="hover:text-red-400 text-sm" >thumb_down</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!-- Spinner -->
      <div class="flex justify-center items-center pb-6"
           *ngIf="waiting && waitingOperation !== 'Write'"
      >
        <mat-progress-spinner
          [diameter]="15"
          [mode]="'indeterminate'"
        ></mat-progress-spinner>
        <span class="animate-pulse ml-3 text-gray-700">{{waitingOperation}}</span>
      </div>
      <!-- Welcome -->
      <div *ngIf="showTheFormForChoosingWhatToOpen" class="p-20">
        <h3 class="text-center">Would you like to open the last dialog or start a new one?</h3>
        <div class="flex flex-wrap sm:p-0 sm:mt-5 md:p-5 sm:justify-center justify-around">
          <button type="button" (click)="choseWhatToOpen(true)" mat-button color="primary">Open the last dialog</button>
          <button type="button" (click)="choseWhatToOpen(false)" mat-button color="primary">Start new chat</button>
        </div>
      </div>
    </vex-scrollbar>
  </div>
  <div class="flex w-full my-2" *ngIf="actionButtonsShow">
    <button *ngFor="let button of actionButtons"
            (click)="buttonClickedActions(button)"
            class="actions-button grow text-sm px-2 py-1 bg-blue-500 rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out"
    >{{ button }}</button>
  </div>
  <div class="flex items-end relative" *ngIf="chatStatus !== 'COMPLETE'">
    <div class="rating-comment absolute w-full bg-white z-[9999]"
         [@expandCollapseRatingComment]
         *ngIf="areRatingCommentVisible">
      <form [formGroup]="formRatingComment">
        <textarea class="input-form__messageInput  max-h-[100px] pt-3"
                  formControlName="comment"
                  [disabled]="waiting"
                  [placeholder]="formRatingComment.controls.isGood.value ? 'Which aspects of the response did you like the most?' : 'What\'s wrong with the response? How to improve it?'"
                  type="text"
                  rows="5"></textarea>
        <div class="text-right">
          <button mat-button mat-button color="primary" [disabled]="waiting"(click)="sendRatingComment(false)">No Thanks</button>
          <button mat-button mat-button color="primary" [disabled]="waiting" cdkFocusInitial (click)="sendRatingComment(true)">Send</button>
        </div>
      </form>
    </div>
    <div class="w-full"
         [@expandCollapseMessageForm]="messageFormState"
    >
      <form class="input-form w-full" [formGroup]="form" fxFlex fxLayoutAlign="start center">
      <textarea class="input-form__messageInput  max-h-[100px]"
                id="textarea1"
                formControlName="message"
                fxFlex="auto"
                [disabled]="waiting"
                [placeholder]="'message'|transloco"
                type="text"
                rows="5"
                [maxlength]="4000"
                #messageInput
                (keydown)="sendBtn($event)" cdkFocusInitial>
      </textarea>

        <button
          class="Chat-button"
          mat-icon-button
          type="button"
          [disabled]="form.disabled"
          (click)="fileInput.click()">
          <mat-icon>attach_file</mat-icon>
        </button>
        <!--      <button class="Chat-button text-red-300 mr-2 w-[20px] hover:text-red-500"-->
        <!--              [disabled]="messages.length <= 1 || waiting"-->
        <!--              mat-icon-button-->
        <!--              type="submit"-->
        <!--              matTooltip="Clear dialogue and close chat"-->
        <!--              (click)="sendCommand(commands.COMPLETE)">-->
        <!--        <mat-icon>close</mat-icon>-->
        <!--      </button>-->
        <button [class.text-primary]="form.get('message').value"
                [disabled]="!form.get('message').value || form.disabled"
                #sendButton
                class="Chat-button w-[20px] mr-5"
                mat-icon-button
                type="submit"
                (click)="sendBtn($event, 'sendButton')">
          <mat-icon>send</mat-icon>
        </button>

        <button *ngIf="(userState$ | async).isAuthenticated" [matMenuTriggerFor]="menu" class="" type="button" mat-icon-button>
          <mat-icon>more_horiz</mat-icon>
        </button>
      </form>
    </div>
  </div>
  <div class="flex w-full items-center relative pl-5 pt-3" *permission="['can-everything']">
    <span class="h-4 text-gray-600 text-sm font-medium">Enable new chat (administrators only)</span>
    <button mat-button
            class="btn_toggle ml-3"
            [ngClass]="{'text-orange-600': !isNewChat, 'text-green-600': isNewChat}"
            (click)="isNewChat = !isNewChat">
      <mat-icon size="14px">
            {{ isNewChat ? 'toggle_on' : 'toggle_off' }}
      </mat-icon>
    </button>
  </div>
</div>

<input #fileInput type="file" [style.display]="'none'" (change)="fileSelected($event)">

<mat-menu #menu="matMenu" yPosition="above" xPosition="before" class="p-4 rounded-lg">
  <mat-radio-group color="primary" class="flex flex-col" aria-label="Select an option" (change)="changeSendSettings($event)"
                   [ngModel]="!!user?.settings?.chatAiSettings?.pressEnterToSend">
    <mat-radio-button [value]="true" class="mb-2">
      <div class="text-xs font-semibold text-gray-500">Press Enter to Send</div>
      <div class="text-xs text-gray-500">Pressing enter will send message</div>
    </mat-radio-button>
    <mat-radio-button [value]="false">
      <div class="text-xs font-semibold text-gray-500">Click Send</div>
      <div class="text-xs text-gray-500">Clicking Send will send message</div>
    </mat-radio-button>
  </mat-radio-group>
</mat-menu>
