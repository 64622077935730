import { PriceItemType } from '../enums/price-item-type.enum';

export const priceItemTypeToTitleMap: Record<PriceItemType, string> = {
  [PriceItemType.RESPONDING_TO_OA]: 'responding to OA',
  [PriceItemType.FILING_AN_APPLICATION]: 'filing application',
  [PriceItemType.RENEWAL]: 'renewal',
  [PriceItemType.PROTECTABILITY_SEARCH]: 'protectability search',
  [PriceItemType.CLAIMING_PRIORITY]: 'claiming priority',
  [PriceItemType.GRANTING_PUBLICATION]: 'grunting',
};
